<template lang="pug">
include ../../../../configs/template
div.row.text-start.pt-2
  div.col-xs-12.col-md-6.col-lg-6.col-xl-6
    +data-info('course', 'documentSailor.course[labelName]')
  div.col-xs-12.col-md-6.col-lg-4.col-xl-3
    +data-info('dateEffective', 'getDateFormat(documentSailor.date_start)')
  div.col-xs-12.col-md-6.col-lg-4.col-xl-3(v-if="documentSailor.date_end")
    +data-info('dateTermination', 'getDateFormat(documentSailor.date_end)')
  div.col-xs-12.col-md-6.col-lg-4.col-xl-3
    +data-info('price', 'documentSailor.price')
  div.col-xs-12.col-md-6.col-lg-4.col-xl-3
    +data-info('priceForm', 'documentSailor.type_of_form === "First" ? $t("firstForm") : $t("secondForm") ')

</template>
<script>
import { getDateFormat } from '@/mixins/main'
import { mapState } from 'vuex'

export default {
  name: 'BackOfficeCoursePricesInfo',
  props: {
    documentSailor: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}

</script>
